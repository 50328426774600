body {
  --primary-background-color: #f0f0f0; //$gray-4
  --secondary-background-color: #f0f2f5; //$background-1
  --third-background-color: #0303030a;

  --primary-font-color: #000000d9; //$gray-12
  --secondary-font-color: #00000073;
  --second-third-font-color: #000000d9; // to fix the issue raised by Liz on the readability of description
  --third-font-color: #00000073; //$gray-14
  --fourth-font-color: #d46b08; // $orange-color7
  --fifth-font-color: #0050b3; //$blue-8;
  --font-6: #262626;
  --font-7: #000000d9;
  --font-8: #003a8c;
  --font-9: #595959;

  --components-border: #00000026; //$gray-13
  --components-background: #ffffff; //$gray-1
  --components-second-background: #f5f5f5; //$gray-3
  --components-third-background: #fff7e6; //$orange-color1;

  --input-text-color: #000000d9; //$gray-12
  --input-background: #ffffff; //$gray-1
  --input-placeholder: #00000040; //$gray-16
  --text-selection: #1890ff; //$blue-6

  --pinned-post-bg: #ffd666;
  --pinned-post-border: #ffd666;
  --pinned-post-icon: #434343;

  --post-header-icon: #434343;
  --post-move-icon: #434343;

  --element-icon: #434343;

  --archive-banner-bg: #fffbe6;
  --archive-banner-border: #ffe58f;

  --draft-banner-bg: #e6f7ff;
  --draft-banner-border: #91d5ff;

  --lock-banner-bg: #fffbe6;
  --lock-banner-border: #ffe58f;
  --lock-btn-border: #00000026;
  --lock-btn-bg: #ffffff;

  --comments-profile-color: #000000;
  --comments-bg: #ffffff;
  --comments-color: #030303;
  --comments-placeholder: #00000040;
  --comments-edit-icon: #00000073;

  --selection-item-bg: #0000000a;
  --selection-item-border: #0000000f;

  --icon-btn: #00000040;
  --close-btn: #000000d9;

  --crown-info-color: #000000d9;

  --attachments-drag-color: #000000d9;
  --attachments-drag-bg: #00000005;
  --attachments-drag-border: #00000026;
  --attachments-max-size-color: #00000073;

  --generate-tags-auto: #000000d9;

  --post-title-metadata-bg: #ffffff80;
  --metadata-close: #000000d9;

  --scroll-bar-color: #acacac;

  --divider-color: #0000000f;

  --drop-down-border: none;

  --tool-tip-bg: #ffffff;
  --tool-tip-color: #000000bf;

  --collapse-icon: #000000bf;

  --footer-default: #434343;

  --selected-generate-tag: #096dd9;

  --back-arrow: #000000d9;

  --attach-comment-share: #434343;
  --attach-delete-icon: #ff4d4f;

  --story-team-name: #262626;
  --story-team-role: #8c8c8c;

  --topic-tags-view-post: #f5f5f5;

  --topics-tags-label-bg: #00000005;

  --global-search-title: #0050b3;

  --global-search-title-match: #391085;

  --global-search-angle-id: #00000073;

  --global-search-created-by: #00000073;

  --global-search-header: #000000d9;

  --global-search-border: #00000026;

  --global-search-bg: #ffffff;

  --global-search-preview-eye-invisible-icon: #0050b3;
  --global-search-page-eye-invisible-icon: #00000073;

  --tabs-fore-color: #000000d9;

  --collapse-panel-header-icons: #000000d9;
  --collapse-panel-header: #0050b3;

  --froala-rt-editor-header-bg: #ffffff;

  --profile-bg: #0303034d;
  --profile-icon: #ffffff;

  --add-icon-bg: #1890ff;
  --close-icon-bg: #262626;
  --search-icon-color: #1890ff;

  --private-angle-overivew: #000000d9;

  --angle-landing-eye-invisible-icon: #00000073;

  --angle-details-eye-invisible-icon: #00000073;

  --eye-icon-bg: #00000005;

  --angle-landing-tab-default: #000000d9;

  --angle-landing-tab-disabled: #00000040;

  --angle-log-section-bg: #ffffff;

  --angle-log-collapse-bg: #ffffff80;

  --create-story-label: #0000008c;

  --create-story-content-section-border: #00000026;

  --create-story-content-section-bg: #ffffff14;

  --create-story-content-bg: #00000005;

  --create-story-metadata-bg: #00000005;

  &.dark {
    // mparillo: I changed this from #0000001A (12/7)
    --primary-background-color: #000;
    --secondary-background-color: #040404;
    --third-background-color: #14141466;

    --primary-font-color: #ffffff;
    --secondary-font-color: #ffffffd9;
    --second-third-font-color: #ffffffa6;
    --third-font-color: #ffffffa6; // $gray-22
    --fourth-font-color: #d46b08; // $orange-color7
    --fifth-font-color: #69c0ff; //$blue-4;
    --font-6: #ffffff;
    --font-7: #ffffffa6;
    --font-8: #69c0ff;
    --font-9: #ffffffa6;

    --components-border: #424242;
    --components-background: #262626;
    --components-second-background: #14141466;
    --components-third-background: #262626;

    --input-text-color: #ffffff;
    --input-background: #141414;
    --input-placeholder: #ffffffa6;
    --text-selection: #1890ff;

    --pinned-post-bg: #141414;
    --pinned-post-border: #ffd666;
    --pinned-post-icon: #ffd666;

    --post-header-icon: #ffffff;
    --post-move-icon: #ffffff;

    --element-icon: #ffffffa6;

    --archive-banner-bg: #2b2111;
    --archive-banner-border: #faad1433;

    --draft-banner-bg: #1890ff33;
    --draft-banner-border: #1890ff33;

    --lock-banner-bg: #2b2111;
    --lock-banner-border: #faad1433;

    --lock-btn-border: #ffffff;
    --lock-btn-bg: #faad1433;

    --comments-profile-color: #ffffff;
    --comments-bg: #141414;
    --comments-color: #ffffff;
    --comments-placeholder: #ffffffa6;
    --comments-edit-icon: #ffffffa6;

    --selection-item-bg: #0000000a;
    --selection-item-border: #424242;

    --icon-btn: #ffffffa6;
    --close-btn: #ffffffa6;

    --crown-info-color: #ffffffa6;

    --attachments-drag-color: #ffffff;
    --attachments-drag-bg: #00000005;
    --attachments-drag-border: #ffffffa6;
    --attachments-max-size-color: #ffffffa6;

    --generate-tags-auto: #ffffffa6;

    --post-title-metadata-bg: #262626;
    --metadata-close: #ffffffa6;

    --scroll-bar-color: #acacac;

    --divider-color: #424242;

    --drop-down-border: #424242;

    --tool-tip-bg: #000000ed;
    --tool-tip-color: #ffffff;

    --collapse-icon: #ffffffa6;

    --footer-default: #ffffff;

    --selected-generate-tag: #096dd9;

    --back-arrow: #ffffffa6;

    --attach-comment-share: #ffffffa6;
    --attach-delete-icon: #ff4d4f;

    --story-team-name: #ffffff;
    --story-team-role: #ffffffa6;

    --topic-tags-view-post: #141414;

    --topics-tags-label-bg: #141414;

    --global-search-title: #1890ff;

    --global-search-title-match: #d3adf7;

    --global-search-angle-id: #ffffffa6;

    --global-search-created-by: #ffffffa6;

    --global-search-header: #ffffffd9;

    --global-search-border: #424242;

    --global-search-bg: #262626;

    --global-search-preview-eye-invisible-icon: #1890ff;
    --global-search-page-eye-invisible-icon: #ffffffd9;

    --tabs-fore-color: #ffffffa6;

    --collapse-panel-header-icons: #ffffffa6;
    --collapse-panel-header: #69c0ff;

    --froala-rt-editor-header-bg: #353535;

    --profile-bg: #bfbfbf;
    --profile-icon: #030303;

    --add-icon-bg: #1890ff;
    --close-icon-bg: #262626;
    --search-icon-color: #1890ff;

    --private-angle-overivew: #ffffffd9;

    --angle-landing-eye-invisible-icon: #ffffffa6;

    --angle-details-eye-invisible-icon: #ffffffd9;

    --eye-icon-bg: #1f1f1f;

    --angle-landing-tab-default: #ffffffa6;

    --angle-landing-tab-disabled: #ffffff4d;

    --angle-log-section-bg: #14141466;

    --angle-log-collapse-bg: #262626;

    --create-story-label: #ffffff8c;

    --create-story-content-section-border: #ffffff14;

    --create-story-content-section-bg: #ffffff14;

    --create-story-content-bg: #ffffff0a;

    --create-story-metadata-bg: #ffffff66;
  }
}
