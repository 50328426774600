@import './utilities/media-queries';

// Margin and Padding classes

// t = top,
// b = bottom,
// r = right,
// l = left

// EXAMPLES:

// margin (with breakpoints): mt-sm-48, mb-sm-48
// padding (with breakpoints): pt-sm-16, pt-md-8

// margin (without breakpoints): mt-48, mb-48, mr-16, ml-16
// padding (without breakpoints): pt-8, pt-16, pr-8, pl-32
// padding all sides (without breakpoints): p-16

$spaceamounts: (0, 8, 12, 16, 24, 32, 40, 48, 56);
$breakpoints: ('sm', 'md', 'lg');
$sides: (top, bottom, right, left);

@each $space in $spaceamounts {
  @each $side in $sides {
    // Margin on only one side
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
    }

    // Padding on only one side
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px;
    }
  }

  // Padding on all sides
  .p-#{$space} {
    @each $side in $sides {
      padding-#{$side}: #{$space}px;
    }
  }

  // Margin on all sides
  .m-#{$space} {
    @each $side in $sides {
      padding-#{$side}: #{$space}px;
    }
  }
}

@each $bp in $breakpoints {
  $media: '';

  @if ($bp == 'sm') {
    $media: $small;
  }
  @if ($bp == 'md') {
    $media: $medium;
  }
  @if ($bp == 'lg') {
    $media: $large;
  }
  @media #{$media} {
    @each $space in $spaceamounts {
      .mt-#{$space}.mt-#{$space} {
        margin-top: #{$space}px;
      }
      .mb-#{$space}.mb-#{$space} {
        margin-bottom: #{$space}px;
      }
      .pt-#{$space}.pt-#{$space} {
        padding-top: #{$space}px;
      }
      .pb-#{$space}.pb-#{$space} {
        padding-bottom: #{$space}px;
      }

      @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$bp}-#{$space} {
          margin-#{$side}: #{$space}px;
        }
        .p#{str-slice($side, 0, 1)}-#{$bp}-#{$space} {
          padding-#{$side}: #{$space}px;
        }
      }
    }
  }
}
