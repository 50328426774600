/*
These are the primary colors used in the Ant Design system.
DO NOT ADD or REMOVE colors from this file.
DO NOT use variables directly from this file.
This file is ONLY FOR REFERENCE. If you need to add or update these colors, it must be done by the UX Design Team and exported out of Figma again.
*/

$ncx-menu: #001529FF;
$ncx-inline: #000C17FF;
$search-menu: var(--global-colors-purple-100);
$search-inline: #000C17FF;
$colors-white-color: #FFFFFFFF;
$colors-white-1: #F5F5F5FF;
$colors-white-2: #FAFAFAFF;
$colors-white-3: #F4F4F4FF;
$colors-white-4: #BFBFBFFF;
$colors-white-5: #F3F3F3FF;
$colors-white-6: #FBFBFBFF;
$colors-white-7: #CCCCCCFF;
$colors-white-8: #E1E1E1FF;
$colors-black-1: #000000FF;
$colors-black-2: #141414FF;
$colors-black-3: #424242FF;
$colors-black-4: #303030FF;
$colors-black-5: #1F1F1FFF;
$colors-black-6: #595959FF;
$colors-black-7: #262626FF;
$colors-black-8: #1D1D1DFF;
$colors-black-9: #070707FF;
$colors-cyan-light-10: #E6FFFBFF;
$colors-daybreak-blue-light-10: #E6F7FFFF;
$colors-dust-red-light-10: #FFF1F0FF;
$colors-dust-red-light-20: #FFCCC7FF;
$colors-dust-red-light-30: #FFA39EFF;
$colors-dust-red-light-40: #FF7875FF;
$colors-dust-red-light-50: #FF4D4FFF;
$colors-dust-red-light-60: #F5222DFF;
$colors-dust-red-light-70: #CF1322FF;
$colors-dust-red-light-80: #A8071AFF;
$colors-dust-red-light-90: #820014FF;
$colors-dust-red-light-100: #5C0011FF;
$colors-dust-red-dark-10: #2A1215FF;
$colors-dust-red-dark-20: #431418FF;
$colors-dust-red-dark-30: #58181CFF;
$colors-dust-red-dark-40: #791A1FFF;
$colors-dust-red-dark-50: #A61D24FF;
$colors-dust-red-dark-60: #D32029FF;
$colors-dust-red-dark-70: #E84749FF;
$colors-dust-red-dark-80: #F37370FF;
$colors-dust-red-dark-90: #F89F9AFF;
$colors-dust-red-dark-100: #FAC8C3FF;
$colors-daybreak-blue-light-20: #BAE7FFFF;
$colors-daybreak-blue-light-30: #91D5FFFF;
$colors-daybreak-blue-light-40: #69C0FFFF;
$colors-daybreak-blue-light-50: #40A9FFFF;
$colors-daybreak-blue-light-60: #1890FFFF;
$colors-daybreak-blue-light-70: #096DD9FF;
$colors-daybreak-blue-light-80: #0050B3FF;
$colors-daybreak-blue-light-90: #003A8CFF;
$colors-daybreak-blue-light-100: #002766FF;
$colors-daybreak-blue-dark-10: #111A2CFF;
$colors-daybreak-blue-dark-20: #112545FF;
$colors-daybreak-blue-dark-30: #15325BFF;
$colors-daybreak-blue-dark-40: #15417EFF;
$colors-daybreak-blue-dark-50: #1554ADFF;
$colors-daybreak-blue-dark-60: #1668DCFF;
$colors-daybreak-blue-dark-70: #3C89E8FF;
$colors-daybreak-blue-dark-80: #65A9F3FF;
$colors-daybreak-blue-dark-90: #8DC5F8FF;
$colors-daybreak-blue-dark-100: #B7DCFAFF;
$colors-cyan-light-20: #B5F5ECFF;
$colors-cyan-light-30: #87E8DEFF;
$colors-cyan-light-40: #5CDBD3FF;
$colors-cyan-light-50: #36CFC9FF;
$colors-cyan-light-60: #13C2C2FF;
$colors-cyan-light-70: #08979CFF;
$colors-cyan-light-80: #006D75FF;
$colors-cyan-light-90: #00474FFF;
$colors-cyan-light-100: #002329FF;
$colors-cyan-dark-10: #112123FF;
$colors-cyan-dark-20: #113536FF;
$colors-cyan-dark-30: #144848FF;
$colors-cyan-dark-40: #146262FF;
$colors-cyan-dark-50: #138585FF;
$colors-cyan-dark-60: #13A8A8FF;
$colors-cyan-dark-70: #33BCB7FF;
$colors-cyan-dark-80: #58D1C9FF;
$colors-cyan-dark-90: #84E2D8FF;
$colors-cyan-dark-100: #B2F1E8FF;
$colors-geek-blue-color: #FFFFFFFF;
$colors-geek-blue-light-10: #F0F5FFFF;
$colors-geek-blue-light-20: #D6E4FFFF;
$colors-geek-blue-light-30: #ADC6FFFF;
$colors-geek-blue-light-40: #85A5FFFF;
$colors-geek-blue-light-50: #597EF7FF;
$colors-geek-blue-light-60: #2F54EBFF;
$colors-geek-blue-light-70: #1D39C4FF;
$colors-geek-blue-light-80: #10239EFF;
$colors-geek-blue-light-90: #061178FF;
$colors-geek-blue-light-100: #030852FF;
$colors-geek-blue-dark-10: #131629FF;
$colors-geek-blue-dark-20: #161D40FF;
$colors-geek-blue-dark-30: #1C2755FF;
$colors-geek-blue-dark-40: #203175FF;
$colors-geek-blue-dark-50: #263EA0FF;
$colors-geek-blue-dark-60: #2B4ACBFF;
$colors-geek-blue-dark-70: #5273E0FF;
$colors-geek-blue-dark-80: #7F9EF3FF;
$colors-geek-blue-dark-90: #A8C1F8FF;
$colors-geek-blue-dark-100: #B7DCFAFF;
$colors-grey-light-0: #0303030A;
$colors-grey-light-2: #03030305;
$colors-grey-light-6: #0303030F;
$colors-grey-light-8: #03030314;
$colors-grey-light-10: #03030319;
$colors-grey-light-15: #03030326;
$colors-grey-light-20: #03030333;
$colors-grey-light-25: #0303033F;
$colors-grey-light-30: #0303034C;
$colors-grey-light-35: #03030359;
$colors-grey-light-40: #03030366;
$colors-grey-light-45: #03030372;
$colors-grey-light-50: #0303037F;
$colors-grey-light-55: #0303038C;
$colors-grey-light-60: #03030399;
$colors-grey-light-65: #030303A5;
$colors-grey-light-70: #030303B2;
$colors-grey-light-75: #030303B2;
$colors-grey-light-80: #030303CC;
$colors-grey-light-85: #030303D8;
$colors-grey-light-90: #030303E5;
$colors-grey-light-96: #030303F4;
$colors-grey-light-100: #030303FF;
$colors-grey-dark-0: #FFFFFF0A;
$colors-grey-dark-2: #FFFFFF05;
$colors-grey-dark-6: #FFFFFF0F;
$colors-grey-dark-8: #FFFFFF14;
$colors-grey-dark-10: #FFFFFF19;
$colors-grey-dark-15: #FFFFFF26;
$colors-grey-dark-20: #FFFFFF33;
$colors-grey-dark-25: #FFFFFF3F;
$colors-grey-dark-30: #FFFFFF4C;
$colors-grey-dark-35: #FFFFFF59;
$colors-grey-dark-40: #FFFFFF66;
$colors-grey-dark-45: #FFFFFF72;
$colors-grey-dark-50: #FFFFFF7F;
$colors-grey-dark-55: #FFFFFF8C;
$colors-grey-dark-60: #FFFFFF99;
$colors-grey-dark-65: #FFFFFFA5;
$colors-grey-dark-70: #FFFFFFB2;
$colors-grey-dark-75: #FFFFFFBF;
$colors-grey-dark-80: #FFFFFFCC;
$colors-grey-dark-85: #FFFFFFD8;
$colors-grey-dark-90: #FFFFFFE5;
$colors-grey-dark-96: #FFFFFFF4;
$colors-grey-dark-100: #FFFFFFFF;
$colors-gold-light-10: #FFFBE6FF;
$colors-gold-light-20: #FFF1B8FF;
$colors-gold-light-30: #FFE58FFF;
$colors-gold-light-40: #FFD666FF;
$colors-gold-light-50: #FFC53DFF;
$colors-gold-light-60: #FAAD14FF;
$colors-gold-light-70: #D48806FF;
$colors-gold-light-80: #AD6800FF;
$colors-gold-light-90: #874D00FF;
$colors-gold-light-100: #613400FF;
$colors-gold-dark-10: #2B2111FF;
$colors-gold-dark-20: #443111FF;
$colors-gold-dark-30: #594214FF;
$colors-gold-dark-40: #7C5914FF;
$colors-gold-dark-50: #AA7714FF;
$colors-gold-dark-60: #D89614FF;
$colors-gold-dark-70: #E8B339FF;
$colors-gold-dark-80: #F3CC62FF;
$colors-gold-dark-90: #F8DF8BFF;
$colors-gold-dark-100: #FAEDB5FF;
$colors-golden-purple-light-10: #F9F0FFFF;
$colors-green-light-10: #F6FFEDFF;
$colors-green-light-20: #D9F7BEFF;
$colors-green-light-30: #B7EB8FFF;
$colors-green-light-40: #95DE64FF;
$colors-green-light-50: #73D13DFF;
$colors-green-light-60: #52C41AFF;
$colors-green-light-70: #389E0DFF;
$colors-green-light-80: #237804FF;
$colors-green-light-90: #135200FF;
$colors-green-light-100: #092B00FF;
$colors-green-dark-10: #162312FF;
$colors-green-dark-20: #1D3712FF;
$colors-green-dark-30: #274916FF;
$colors-green-dark-40: #306317FF;
$colors-green-dark-50: #3C8618FF;
$colors-green-dark-60: #49AA19FF;
$colors-green-dark-70: #6ABE39FF;
$colors-green-dark-80: #8FD460FF;
$colors-green-dark-90: #B2E58BFF;
$colors-green-dark-100: #D5F2BBFF;
$colors-golden-purple-light-20: #EFDBFFFF;
$colors-golden-purple-light-30: #D3ADF7FF;
$colors-golden-purple-light-40: #B37FEBFF;
$colors-golden-purple-light-50: #9254DEFF;
$colors-golden-purple-light-60: #722ED1FF;
$colors-golden-purple-light-70: #531DABFF;
$colors-golden-purple-light-80: #391085FF;
$colors-golden-purple-light-90: #22075EFF;
$colors-golden-purple-light-100: #120338FF;
$colors-golden-purple-dark-10: #1A1325FF;
$colors-golden-purple-dark-20: #24163AFF;
$colors-golden-purple-dark-30: #301C4DFF;
$colors-golden-purple-dark-40: #3E2069FF;
$colors-golden-purple-dark-50: #51258FFF;
$colors-golden-purple-dark-60: #642AB5FF;
$colors-golden-purple-dark-70: #854ECAFF;
$colors-golden-purple-dark-80: #AB7AE0FF;
$colors-golden-purple-dark-90: #CDA8F0FF;
$colors-golden-purple-dark-100: #EBD7FAFF;
$colors-lime-light-10: #FCFFE6FF;
$colors-lime-light-20: #F4FFB8FF;
$colors-lime-light-30: #EAFF8FFF;
$colors-lime-light-40: #D3F261FF;
$colors-lime-light-50: #BAE637FF;
$colors-lime-light-60: #A0D911FF;
$colors-lime-light-70: #7CB305FF;
$colors-lime-light-80: #5B8C00FF;
$colors-lime-light-90: #3F6600FF;
$colors-lime-light-100: #254000FF;
$colors-lime-dark-10: #1F2611FF;
$colors-lime-dark-20: #2E3C10FF;
$colors-lime-dark-30: #3E4F13FF;
$colors-lime-dark-40: #536D13FF;
$colors-lime-dark-50: #6F9412FF;
$colors-lime-dark-60: #8BBB11FF;
$colors-lime-dark-70: #A9D134FF;
$colors-lime-dark-80: #C9E75DFF;
$colors-lime-dark-90: #E4F88BFF;
$colors-lime-dark-100: #F0FAB5FF;
$colors-magenta-light-10: #FFF0F6FF;
$colors-orange-light-10: #FFF7E6FF;
$colors-volcano-red-light-10: #FFF2E8FF;
$colors-volcano-red-light-20: #FFD8BFFF;
$colors-volcano-red-light-30: #FFBB96FF;
$colors-volcano-red-light-40: #FF9C6EFF;
$colors-volcano-red-light-50: #FF7A45FF;
$colors-volcano-red-light-60: #FA541CFF;
$colors-volcano-red-light-70: #D4380DFF;
$colors-volcano-red-light-80: #AD2102FF;
$colors-volcano-red-light-90: #871400FF;
$colors-volcano-red-light-100: #5C0011FF;
$colors-volcano-red-dark-10: #2B1611FF;
$colors-volcano-red-dark-20: #441D12FF;
$colors-volcano-red-dark-30: #592716FF;
$colors-volcano-red-dark-40: #7C3118FF;
$colors-volcano-red-dark-50: #AA3E19FF;
$colors-volcano-red-dark-60: #D84A1BFF;
$colors-volcano-red-dark-70: #E87040FF;
$colors-volcano-red-dark-80: #F3956AFF;
$colors-volcano-red-dark-90: #F8B692FF;
$colors-volcano-red-dark-100: #FAD4BCFF;
$colors-yellow-light-10: #FEFFE6FF;
$colors-yellow-light-20: #FFFFB8FF;
$colors-yellow-light-30: #FFFB8FFF;
$colors-yellow-light-40: #FFF566FF;
$colors-yellow-light-50: #FFEC3DFF;
$colors-yellow-light-60: #FADB14FF;
$colors-yellow-light-70: #D4B106FF;
$colors-yellow-light-80: #AD8B00FF;
$colors-yellow-light-90: #876800FF;
$colors-yellow-light-100: #614700FF;
$colors-yellow-dark-10: #2B2611FF;
$colors-yellow-dark-20: #443B11FF;
$colors-yellow-dark-30: #595014FF;
$colors-yellow-dark-40: #7C6E14FF;
$colors-yellow-dark-50: #AA9514FF;
$colors-yellow-dark-60: #D8BD14FF;
$colors-yellow-dark-70: #E8D639FF;
$colors-yellow-dark-80: #F3EA62FF;
$colors-yellow-dark-90: #F8F48BFF;
$colors-yellow-dark-100: #FAFAB5FF;
$colors-orange-light-20: #FFE7BAFF;
$colors-orange-light-30: #FFD591FF;
$colors-orange-light-40: #FFC069FF;
$colors-orange-light-50: #FFA940FF;
$colors-orange-light-60: #FA8C16FF;
$colors-orange-light-70: #D46B08FF;
$colors-orange-light-80: #AD4E00FF;
$colors-orange-light-90: #873800FF;
$colors-orange-light-100: #612500FF;
$colors-orange-dark-10: #2B1D11FF;
$colors-orange-dark-20: #442A11FF;
$colors-orange-dark-30: #593815FF;
$colors-orange-dark-40: #7C4A15FF;
$colors-orange-dark-50: #AA6215FF;
$colors-orange-dark-60: #D87A16FF;
$colors-orange-dark-70: #E89A3CFF;
$colors-orange-dark-80: #F3B765FF;
$colors-orange-dark-90: #F8CF8DFF;
$colors-orange-dark-100: #FAE3B7FF;
$colors-magenta-light-20: #FFD6E7FF;
$colors-magenta-light-30: #FFADD2FF;
$colors-magenta-light-40: #FF85C0FF;
$colors-magenta-light-50: #F759ABFF;
$colors-magenta-light-60: #EB2F96FF;
$colors-magenta-light-70: #C41D7FFF;
$colors-magenta-light-80: #9E1068FF;
$colors-magenta-light-90: #780650FF;
$colors-magenta-light-100: #520339FF;
$colors-magenta-dark-10: #291321FF;
$colors-magenta-dark-20: #40162FFF;
$colors-magenta-dark-30: #551C3BFF;
$colors-magenta-dark-40: #75204FFF;
$colors-magenta-dark-50: #A02669FF;
$colors-magenta-dark-60: #CB2B83FF;
$colors-magenta-dark-70: #E0529CFF;
$colors-magenta-dark-80: #F37FB7FF;
$colors-magenta-dark-90: #F8A8CCFF;
$colors-magenta-dark-100: #FAD2E3FF;
