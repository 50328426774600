@font-face {
  font-family: 'SF Pro Display';
  font-style: italic;
  src: url('/assets/fonts/SF-Pro-Display/woff2/SF-Pro-Display-RegularItalic.woff2') format('woff2'),
  url('/assets/fonts/SF-Pro-Display/ttf/SF-Pro-Display-RegularItalic.ttf') format('truetype'),
  url('/assets/fonts/SF-Pro-Display/otf/SF-Pro-Display-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF-Pro-Display/woff2/SF-Pro-Display-Regular.woff2') format('woff2'),
  url('/assets/fonts/SF-Pro-Display/ttf/SF-Pro-Display-Regular.ttf') format('truetype'),
  url('/assets/fonts/SF-Pro-Display/otf/SF-Pro-Display-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: url('/assets/fonts/SF-Pro-Display/woff2/SF-Pro-Display-Bold.woff2') format('woff2'),
  url('/assets/fonts/SF-Pro-Display/ttf/SF-Pro-Display-Bold.ttf') format('truetype'),
  url('/assets/fonts/SF-Pro-Display/otf/SF-Pro-Display-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Display Semi Bold';
  src: url('/assets/fonts/SF-Pro-Display/woff2/SF-Pro-Display-Semibold.woff2') format('woff2'),
  url('/assets/fonts/SF-Pro-Display/ttf/SF-Pro-Display-Semibold.ttf') format('truetype'),
  url('/assets/fonts/SF-Pro-Display/otf/SF-Pro-Display-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/woff2/Montserrat-Regular.woff2') format('woff2'),
  url('/assets/fonts/Montserrat/ttf/Montserrat-Regular.ttf') format('truetype'),
  url('/assets/fonts/Montserrat/otf/Montserrat-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/woff2/Montserrat-Medium.woff2') format('woff2'),
  url('/assets/fonts/Montserrat/ttf/Montserrat-Medium.ttf') format('truetype'),
  url('/assets/fonts/Montserrat/otf/Montserrat-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/woff2/Montserrat-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/Montserrat/ttf/Montserrat-SemiBold.ttf') format('truetype'),
  url('/assets/fonts/Montserrat/otf/Montserrat-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat/woff2/Montserrat-Bold.woff2') format('woff2'),
  url('/assets/fonts/Montserrat/ttf/Montserrat-Bold.ttf') format('truetype'),
  url('/assets/fonts/Montserrat/otf/Montserrat-Bold.otf') format('opentype');
  font-weight: 700;
}
